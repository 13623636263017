import React from 'react';
import { graphql } from 'gatsby';
import { Cell, Grid } from 'styled-css-grid';

function Projects(props) {
  return (
    <>
      <Grid columns={1}>
        <Cell>
        </Cell>
      </Grid>
    </>
  );
}

export default Projects;
